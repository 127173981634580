<template>
  <b-card
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
    
      <v-grid
        :source="source" 
        :columns="columns" 
        theme="material" 
        resize="true"
        autoSizeColumn = true
        range="true"
        rowClass="highlighted"
        class="grid-container-data-gathering" 
      ></v-grid>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { useRouter } from '@core/utils/utils'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'

export default {
  components: {
    BCard,

    CustomLoading,

    VGrid,
  },
  setup() {
    const source = ref([])
    const columns = ref([])
    const isLoading = ref(true)

    const getFileData = async () => {
      try {
        isLoading.value = true
        const { route } = useRouter()
        const fileId = route.value.params.fileId
        const areaId = route.value.params.areaId
        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/get_excel_data`, 
          {
            file_id: fileId,
            area_id: areaId
          })
        columns.value = result.data.result.columns
        source.value = result.data.result.rows
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    getFileData()

    return {
      source,
      columns,
      isLoading
    }
  }
}
</script>

<style lang="scss">
.grid-container-data-gathering {
  width: 100%;
  height: 85vh;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}
</style>
